import React, { PureComponent } from 'react'
import styles from './Recently.module.css';

export default class Recently extends PureComponent {
  render() {
    return (
      <div className={styles.Container}>
        <div className={styles.Content_Container}>
          <div className={styles.Scrollable_Container}>
            <div className={styles.CV_Point}>
              <div className={styles.CV_Header}>
                <h3>LiveList, <span className={styles.CV_Location}>Venice, CA</span> — <span className={styles.CV_Title}>Lead Fullstack Developer</span></h3>
              </div>
              <div className={styles.CV_Dates}>
                <h5>NOVEMBER 2017 - JUNE 2019</h5>
              </div>
              <ul className={styles.CV_Description}>
                <li>Served as the lead knowledge source for frontend and backend development and was responsible for final decision-making in regards to the company’s stack, middleware integrations, and any/all technology partners that enabled the platform</li>
                <li>Designed and made decisions regarding the overall architecture of the web and mobile applications.</li>
                <li>Contributed to direction of, managed, and implemented product roadmap</li>
                <li>Executed stories within the framework of an agile style of development, following all standards & steps for development process</li>
                <li>Maintained code integrity and organization, best practices of security and data protection, and ensured quality and responsiveness of applications</li>
              </ul>
            </div>
            <div className={styles.CV_Point}>
              <div className={styles.CV_Header}>
                <h3>Treatment.com, <span className={styles.CV_Location}>Remote</span> — <span className={styles.CV_Title}>Software Developer/Consultant</span></h3>
              </div>
              <div className={styles.CV_Dates}>
                <h5>MARCH 2017 - CURRENT</h5>
              </div>
              <ul className={styles.CV_Description}>
                <li>Implementing and advising on an object/document editor including versioning and referencing, admin panel for managing users’ privileges and assigned objects, as well as structure of objects, versioning, and storage.</li>
              </ul>
            </div>
            <div className={styles.CV_Point}>
              <div className={styles.CV_Header}>
                <h3>Botwork, <span className={styles.CV_Location}>Remote</span> — <span className={styles.CV_Title}>Fullstack Engineer</span></h3>
              </div>
              <div className={styles.CV_Dates}>
                <h5>OCTOBER 2016 - FEBRUARY 2017</h5>
              </div>
              <ul className={styles.CV_Description}>
                <li>Developed the client portal, including real-time chat, for a text marketing platform utilizing 'chatbots' and addons tailored to various use cases</li>
                <li>Designed and implemented server logic, conversation routing/logic, APIs, and database schema</li>
              </ul>
            </div>
            <div className={styles.CV_Point}>
              <div className={styles.CV_Header}>
                <h3>NOVO, <span className={styles.CV_Location}>Remote</span> — <span className={styles.CV_Title}>Fullstack Engineer</span></h3>
              </div>
              <div className={styles.CV_Dates}>
                <h5>FEBRUARY 2016 - NOVEMBER 2016</h5>
              </div>
              <ul className={styles.CV_Description}>
                <li>Built responsive views/interfaces with AngularJS on top of Sails.js (built on Node.js, Express, Socket.io), built common services and constantly resolved issues and Promises</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
