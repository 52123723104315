import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'
import styles from './BackgroundImage.module.css'
import { withRouter } from 'react-router-dom'
import Overlay from './Overlay'

class BackgroundImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }

    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
  }

  handleImageLoaded() {
    this.setState({
      loading: false
    });
  }

  handleImageError() {
    this.setState({
      loading: false,
      failedToLoad: true
    })
  }

  componentDidMount() {
    this.image = new Image();
    this.image.src = this.props.src;
    this.image.onload = this.handleImageLoaded;
    this.image.onerror = this.handleImageError;
  }

  render() {
    let backgroundStyle = Object.assign({
      backgroundImage: `url(${this.props.src})`
    }, this.props.customStyle);

    let overlayStyle = {};
    switch (this.props.location.pathname) {
      case '/recently':
        overlayStyle.opacity = '.8'
      break;
      case '/contact':
        overlayStyle.opacity = '.8'
      break;
      default:
        overlayStyle.opacity = '.5'
      break;
    }

    const antlyBackThatShitUpEventuallyMaybe =
      <CSSTransition
        in={!this.state.loading}
        timeout={1500}
        classNames="background-image"
        unmountOnExit
      >
        <div className={styles.God} style={backgroundStyle}>
          <Overlay customStyle={overlayStyle} />
          {this.props.children}
        </div>
      </CSSTransition>

    return antlyBackThatShitUpEventuallyMaybe;
  }
}

export default withRouter(BackgroundImage);

