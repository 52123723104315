import React, { PureComponent } from 'react'
import styles from './Contact.module.css'
import { ReactComponent as GithubLogo } from './github.svg'
import { ReactComponent as LinkedinLogo } from './linkedin.svg'
import { ReactComponent as EmailLogo } from './envelope.svg'

export default class Contact extends PureComponent {
  render() {
    return (
      <div className={styles.Container}>
        <div className={styles.Content_Container}>
          <div className={styles.Content}>
            <div className={styles.Links_Tray}>
              <a href="https://www.linkedin.com/in/modestjoust/" target="_blank" rel="noopener noreferrer">
                <LinkedinLogo className={styles.Social_Logo} />
              </a>
              <a href="https://github.com/modestjoust" target="_blank" rel="noopener noreferrer">
                <GithubLogo className={styles.Social_Logo} />
              </a>
              <a href="mailto:contact@taylormak.es" target="_blank" rel="noopener noreferrer">
                <EmailLogo className={styles.Social_Logo} />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
