import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import styles from './Header.module.css'

class Header extends Component {
  render() {
    return (
      <nav className={styles.Header}>
        <ul>
          <li>
            <NavLink activeClassName={styles.Active_Link} exact={true} to="/">
              Slightly Dated Picture of Me with a Short and Underwhelming Tagline
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={styles.Active_Link} to="/recently">
              Recent Work
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={styles.Active_Link} to="/contact">
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>
    )
  }
}

export default Header
