import React from 'react';
import { CSSTransition } from 'react-transition-group'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import Recently from './Recently';
import Contact from './Contact';
import BackgroundImage from './BackgroundImage';
import ylt from './ylt_orig_scaled_grayscale-compressor.jpg'
import styles from './App.css'

const backgroundImageStyle = {
  filter: 'saturate(100%) contrast(100%) grayscale(100%) blur(0px)',
};

const routes = [
  { path: '/', name: 'Home', Component: Home },
  { path: '/recently', name: 'Recently', Component: Recently },
  { path: '/contact', name: 'Contact', Component: Contact },
]

function AppRouter() {
  return (
    <Router>
      <Header />
      {routes.map(({ path, Component }) => (
        <Route key={path} exact path={path}>
          {({ match }) => (
            <CSSTransition
              in={match != null}
              timeout={200}
              classNames="page"
              unmountOnExit
            >
              <div className="page">
                <Component />
              </div>
            </CSSTransition>
          )}
        </Route>
      ))}
      <BackgroundImage src={ylt} customStyle={backgroundImageStyle}></BackgroundImage>
    </Router>
  );
}

export default AppRouter;