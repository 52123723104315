import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import styles from './Home.module.css'
import Button from './Button'

export default class Home extends PureComponent {
  render() {
    return (
      <div className={styles.Container}>
        <div className={styles.Content_Container}>
          <div className={styles.Tagline_Container}>
            <h3 className={styles.Tagline}>- "Yeah, let's build that."</h3>
            <Link to="/contact">
              <Button label="Okay.">
                Okay.
              </Button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
